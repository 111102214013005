<template>
  <div>
    <section-banner
      v-for="(section, index) in sections"
      :key="index"
      :model="section"
    ></section-banner>
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m11!1m3!1d12530.040081225437!2d28.028922405533876!3d-26.02426709247975!2m2!1f0!2f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95741ae2600d07%3A0xac1093884b381542!2s24%20Crawford%20Dr%2C%20Norscot%2C%20Sandton%2C%202055!5e1!3m2!1sen!2sza!4v1606159814747!5m2!1sen!2sza"
          width="100%"
          height="500"
          id="gmap_canvas"
          frameborder="0"
          style="border:0;"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: "Contact",
  components: {
    SectionBanner,
  },
  data() {
    return {
      sections: [
        {
          Title: "Contact Us",
          Subtitle: "What's on your mind?",
          img: require("@/assets/img/Vuyani5.jpg"),
        },
        {
          TextColumn: true,
          TextColumn1: `<p class="contact__link">Tel: <a href="tel:+27117041563">+27 11 704 1563</a><br>Email: <a href="mailto:products@NLAteam.com">products@NLAteam.com</a><br></p>
                        <h3 class="contact__title">Address</h3>
                        <p class="contact__link">24 Crawford Dr<br>
                        Norscot<br>
                        Sandton<br>
                        2055
                        <a href="mailto:products@NLAteam.com"></a><br></p>`,
          TextColumn2: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 100%;
}
</style>
